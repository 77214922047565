@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.TitleAndImageHero {
  padding: 0 var(--page-gutter);
}

.inner {
  @include grid;
  row-gap: 0;
}

.titleContainer {
  grid-column: 1/-1;
  margin-bottom: px(24);

  @include bp(mobile) {
    margin-bottom: px(32);
  }
}

.title {
  @include font-h1;
}

.richTextContainer {
  grid-column: 1/-1;
  max-width: px(800);

  p {
    @include font-body-large;
  }
}

.imageContainer {
  margin-top: px(40);
  grid-column: 1/-1;
  aspect-ratio: 1/1;
  position: relative;

  @include bp(mobile) {
    aspect-ratio: 1296/729;
    margin-top: px(80);
  }
}

.image {
  @include position-100(absolute);
  object-fit: cover;
}
