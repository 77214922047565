@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.TeamMembers {
  padding: 0 var(--page-gutter);
}

.inner {
  @include grid;
}

.titleColumn {
  grid-column: 1/-1;
  margin-bottom: px(45);

  @include bp(mobile) {
    margin-bottom: 0;
    grid-column: 1/6;
  }
}

.titleContainer {
  @include bp(mobile) {
    position: sticky;
    top: var(--header-height);
  }
}

.title {
  @include font-h3;
  white-space: pre-line;
}

.membersColumn {
  grid-column: 1/-1;
  display: flex;
  flex-direction: column;
  gap: px(40);

  @include bp(mobile) {
    grid-column: 6/-1;
  }
}

.person__inner {
  display: grid;
  grid-template-columns: 1fr;
  gap: px(24);
  align-items: center;

  @include bp(mobile) {
    gap: px(40);
    grid-template-columns: 0.4fr 0.6fr;
  }
}

.person__imageContainer {
  aspect-ratio: 1/1;
  position: relative;
}

.person__image {
  @include position-100(absolute);
  object-fit: cover;
  mask-image: url('/team-member-mask.svg');
  mask-repeat: no-repeat;
  mask-size: cover;
}

.person__nameContainer {
  margin-bottom: px(15);
}

.person__name {
  @include font-h4;
}

.person__designation {
  @include font-small-subheader;
  color: var(--text-color-subtle);
}

.person__bioContainer {
  margin-top: px(24);
}

.person__bio {
  @include font-body;
  color: var(--text-color-subtle);
}
