@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.AnimatedTextLines {
}

.inner {
  [data-type='fadeIn'] & {
    opacity: 0;
  }

  [data-type='fadeInUp'] & {
    opacity: 0;
    transform: translateY(#{px(40)});
  }
}
