@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.AnimatedTextLines {
  opacity: 0;

  &.rendered {
    opacity: 1;
  }
}

.line {
  opacity: 0;
  transform: translateY(#{px(24)});

  @include bp(mobile) {
    transform: translateY(#{px(40)});
  }

  .hasFinishesAnimated & {
    opacity: 1;
    transform: none;
  }
}
