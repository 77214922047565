@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.PaginatedBlogPosts {
  padding: 0 var(--page-gutter);
}

.header__title {
  @include font-h1;
  margin-bottom: px(24);
  white-space: pre-line;
}

.header__description {
  @include font-body-large;
  color: var(--text-color-subtle);
  max-width: px(756);
}

.filters {
  @include hide-scrollbar;
  margin-top: px(20);
  border-bottom: 1px solid var(--button-border);
  width: calc(100% + calc(var(--page-gutter) * 2));
  margin-left: calc(var(--page-gutter) * -1);
  overflow: auto;
  position: relative;

  .noFilters & {
    margin-top: px(40) !important;
  }

  @include bp(tablet) {
    padding-bottom: px(24);
    margin-top: px(80);
    margin-left: 0;
    overflow: hidden;
    width: auto;
  }
}

.filters__inner {
  display: flex;
  gap: px(32);
  width: min-content;
  padding: px(16) var(--page-gutter);

  .noFilters & {
    display: none;
  }

  @include bp(tablet) {
    width: 100%;
    padding: 0;
  }
}

.filters__title {
  @include font-small-subheader;
  display: block;
}

.filters__list {
  display: flex;
  align-items: center;
  gap: px(32);
}

.filters__listItem {
  display: block;
}

.filters__button {
  @include reset-button;
  @include font-small-subheader;
  display: block;
  transition: opacity 0.2s;
  white-space: nowrap;

  &.activeFilter {
    font-weight: 700;
  }

  &.notAll:not(.activeFilter) {
    opacity: 0.5;
  }
}

.items {
  display: flex;
  flex-flow: row wrap;
  column-gap: var(--gutter);
  row-gap: px(40);
  padding-top: px(32);

  @include bp(tablet) {
    padding-top: px(40);
  }
}

.loadMoreButtonContainer {
  @include flex-center;
  margin-top: px(60);

  @include bp(tablet, true) {
    margin-bottom: px(40);
  }

  @include bp(tablet) {
    margin-top: px(80);
  }
}

.loadMoreButton {
  @include reset-button;
  @include font-button;
  border-bottom: 1px solid var(--button-border);
}
