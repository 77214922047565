@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.HomepageHero {
  @include flex-center;
  color: var(--text-color);
}

.inner {
  height: 100lvh;
  position: relative;
  width: 100%;
}

.titleContainer {
  height: 100lvh;
  width: 100%;
  position: sticky;
  top: 0;
  overflow: hidden;
}

.titleContainer__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1.02) translateY(-1%); // hack for image not appearing right at top
}

.title {
  @include font-h1;
  display: grid;
  grid-template-areas: 'a';
  z-index: 3;
  text-align: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.title__inner__large {
  white-space: pre-line;
  display: block;
  font-size: 9.3vw;
  line-height: 10.6vw;

  @include bp(mobile) {
    font-size: 6.3vw;
    line-height: 7vw;
  }
}

.title__inner {
  grid-area: a;
  color: var(--text-color-invert);
  opacity: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.shapeContainer {
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100lvh;
  overflow: hidden;
}

.arrowContainer {
  position: absolute;
  bottom: px(24);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: px(10);
  z-index: 5;
}

.arrowContainer__text {
  @include font-small-subheader;
}

.arrowContainer__arrow {
  width: px(32);
  transform: rotate(90deg);
}
