@import "@/styles/shared";
.Header {
  display: flex;
}

.title {
  @include font-h3;
  text-wrap: auto !important;
  display: flex;
  flex-grow: 1;
  transition: color 0.3s;
  padding: px(24) px(45) px(24) 0;

  @include bp(mobile) {
    padding: px(24) px(60) px(24) px(24);
  }

  .Header.isHovering:not(.isOpen) & {
    color: var(--white);
  }
}

.titleOpenState {
  color: var(--primary-color);
}

.dropdownButton {
  @include reset-button;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  transition: opacity 0.6s ease-in-out;
  position: relative;
}

.dropdownButtonOpenState {
  opacity: 1;
}

.iconWrapper {
  padding-top: px(8);
  transition: transform 0.3s ease-in-out;
  width: var(--dimensions);
  height: var(--dimensions);
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  --dimensions: #{px(24)};

  @include bp(mobile) {
    right: px(24);
  }
}

.lineVertical,
.lineHorizontal {
  background-color: var(--text-color);
  grid-area: a;
  position: absolute;
  transition: background-color 0.3s, opacity 0.3s;

  .Header.isHovering:not(.isOpen) & {
    background-color: var(--white);
  }
}

.lineVertical {
  width: 2px;
  height: var(--dimensions);
  top: 0;
  left: calc(var(--dimensions) * 0.5);
  transform: translateX(-50%);

  .iconWrapperOpenState & {
    opacity: 0;
  }
}

.lineHorizontal {
  height: 2px;
  width: var(--dimensions);
  top: calc(var(--dimensions) * 0.5);
  left: 0;
  transform: translateY(-50%);
}
