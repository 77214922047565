@import "@/styles/shared";
.p,
.li {
  @include font-body;
  color: var(--text-color-subtle);

  a {
    text-decoration: underline;
  }
}

.p {
  margin: 1.1em 0;
}

.ul {
  list-style: disc;
  padding-left: px(15);
}
