@import "@/styles/shared";
.VimeoPlayer {
  &.mutedAndLooping {
    pointer-events: none;
  }
}

.innerPlayer {
  aspect-ratio: var(--aspect-ratio);
  position: relative;
  overflow: hidden;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
