@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.SectionContainer {
  margin: var(--section-spacing) 0;
  min-height: px(50); // hack for fixing page-to-page issues with sections indicating they're in view
  color: var(--text-color);
  background-color: var(--bg-color);

  &:first-child {
    margin-top: 0;
    padding-top: var(--content-top-spacing);
  }

  &:last-child {
    margin-bottom: var(--content-footer-spacing);
  }
}

@each $colour in $background-colours {
  .SectionContainer[data-section-background='#{$colour}'] {
    @if $colour == 'light' {
      @include light-theme;
    }

    // if first child
    &:first-child {
      padding-top: var(--content-top-spacing) !important;
    }

    @if $colour == 'dark' {
      @include dark-theme;

      // if before footer and dark them,
      // do this padding
      &:nth-last-child(2) {
        padding-bottom: var(--content-footer-spacing) !important;
      }
    }

    &:not([data-section-background-previous='#{$colour}']) {
      padding-top: var(--section-spacing);
      margin-top: 0;
    }

    &:not([data-section-background-next='#{$colour}']) {
      padding-bottom: var(--section-spacing);
      margin-bottom: 0;
    }

    // &[data-section-background-next='#{$colour}'] {
    //   margin-bottom: 0;
    // }

    // & + [data-section-background='#{$colour}'] {
    //   margin-top: 0;
    //   padding-top: var(--section-spacing) !important;
    // }
  }
}

// Anti-Pattern OVERRIDES

// The extra selectors are purely for overriding
section.SectionContainer[data-component='homepageHero'][data-section-background] {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.SectionContainer[data-component='footer'] {
  margin-top: var(--content-footer-spacing);
  padding-top: px(77) !important;
  padding-bottom: px(77) !important;

  &[data-section-background-previous='dark'] {
    margin-top: 0;
    border-top: 1px solid var(--text-color);
  }

  &:has([data-extra-padding='true']) {
    padding-bottom: var(--section-spacing) !important;
  }
}

section.SectionContainer[data-component='yellowQuote'][data-section-background] {
  margin-top: calc(var(--section-spacing) * -1);
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

section.SectionContainer[data-component='accordion'] {
  margin-top: var(--content-footer-spacing);
  margin-bottom: var(--content-footer-spacing);
}

section.SectionContainer[data-component='fourOhFour'][data-section-background] {
  padding: 0 !important;
  margin: 0 !important;
}
