@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.HomepageHeroShape {
  @include flex-center;
  overflow: hidden;
  position: relative;
  height: 100lvh;
  width: 100%;
  opacity: 0;
}

.svg {
  // min-width: var(--size);
  height: 100lvh;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.image {
  @include position-100(absolute);
  height: 100lvh;
  object-fit: cover;
  z-index: 1;
}

.svgMask {
  path {
    fill: white;
    stroke: white;
  }
}

.svgGroupFilled {
  path {
    fill: var(--grey-light);
    stroke: var(--dark-purple-light-2);
  }
}

.svgPathImage {
  transform: translate(0.04%, 0.61%);
}

.imageToLoad {
  @include position-100(absolute);
  z-index: 5;
  object-fit: cover;
  opacity: 0.01;
  pointer-events: none;
}
