@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.RichTextWithIcon {
  padding: 0 var(--page-gutter);
}

.inner {
  display: grid;
  grid-template-columns: #{px(40)} 1fr;
  column-gap: #{px(16)};

  @include bp(mobile) {
    @include grid;
  }
}

.iconContainer {
  @include bp(mobile) {
    grid-column: 1/2;
  }

  svg {
    max-width: 73%;
    height: auto !important;
  }
}

.textContainer {
  grid-column: 2/-1;

  @include bp(laptop) {
    grid-column: 2/-1;
  }
}

.titleContainerInner {
  max-width: px(1060);
}

.eyebrowContainer {
  margin-bottom: px(8);
}

.eyebrow {
  @include font-subheader;
  color: var(--text-color-subtle);
}
