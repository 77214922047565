@import "@/styles/shared";
/* stylelint-disable */
.ContactForm {
  padding: 0 var(--page-gutter);

  --column-span: 1/-1;

  @include bp(mobile) {
    --column-span: 1/9;
  }

  @include bp(laptop) {
    --column-span: 1/7;
  }

  &.fullWidth {
    padding: 0;
  }
}

.inner {
  @include grid;

  .fullWidth & {
    display: block;
  }
}

.textContent {
  grid-column: var(--column-span);
  position: relative;

  .fullWidth & {
    grid-column: auto;
  }
}

.successText {
  @include font-h3;
  white-space: pre-line;
  position: absolute;
  top: calc(100% + #{px(20)});
  width: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  width: 100%;

  @include bp(mobile) {
    top: calc(100% + #{px(50)});
    width: calc(100% + #{px(140)});
  }
}

.eyebrow {
  @include font-subheader;
  margin-bottom: px(8);
}

.title {
  @include font-h2;
  margin-bottom: px(24);
}

.description {
  @include font-body-large;
  color: var(--text-color-subtle);
}

.formContainer {
  grid-column: var(--column-span);
  margin: px(40) 0 px(22);
}

.textareaContainer {
  margin-top: px(40);
}

.textAreaContainer__label {
  margin-bottom: px(10);
  display: inline-flex;
  color: var(--text-color-subtle);
}

.textarea {
  @include font-body;
  padding: px(16);
  border: 1px solid var(--input-border-color);
  height: px(200);
  border-radius: 2px;
}

.simpleSuccessMessage {
  @include font-body;
}

.formSubmit,
.simpleSuccessMessage {
  margin-top: px(40);
}

.smallTextContainer {
  grid-column: var(--column-span);
}

.smallText {
  color: var(--text-color-subtle);
}

/* ========================
Input
======================== */

.inputContainer {
  position: relative;
}

.input {
  appearance: none;

  .hasValue & {
    border-color: var(--purple);
  }

  .hasError & {
    border-color: var(--red);
  }
}

.label {
  @include font-body-large;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  position: absolute;
  transition: transform 0.2s;

  .input:focus + &,
  .hasValue &,
  .hasValue + & {
    transform: scale(0.8) translate(-12%, -210%);

    @include bp(tablet) {
      transform: scale(0.6) translate(-34%, -230%);
    }
  }
}

/* ========================
Select
======================== */

.selectContainer {
  position: relative;

  .label {
    pointer-events: none;
  }
}

.select {
  color: var(--text-color-subtle);

  &.hasValue {
    color: var(--text-color);
    border-color: var(--purple);
  }

  .hasError & {
    border-color: var(--red);
  }
}

.chevronDown {
  width: px(10);
  position: absolute;
  top: px(22);
  right: px(15);
  pointer-events: none;
}

.input,
.select {
  height: px(50);
  border-radius: 0px;
}

.textAreaContainer__label,
.input,
.select {
  @include font-body-large;
}

.input,
.select {
  appearance: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--input-border-color);
  padding-left: 0;
}

.input,
.textarea {
  color: var(--text-color);

  &::placeholder {
    color: var(--text-color-subtle);
  }
}

.selectContainer,
.inputContainer {
  margin-bottom: px(40);
  transition: margin-bottom 0.2s;

  @include bp(mobile) {
    margin-bottom: px(46);
  }

  &.hasError {
    margin-bottom: px(55);

    @include bp(mobile) {
      margin-bottom: px(60);
    }
  }
}

.input,
.select,
.textarea {
  background-color: transparent;
  box-sizing: border-box;
  width: 100%;
  display: block;
  transition: border-color 0.3s;
  outline: none;
}

.error {
  @include font-small-subheader;
  position: absolute;
  left: 0;
  top: 100%;
  color: var(--red);
  padding-top: px(5);

  .isPdfRequest & {
    position: static;
  }
}

.checkboxLabel {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: px(24);

  input {
    margin: px(3) px(8) 0 0;

    @include bp(tablet) {
      margin: px(5) px(8) 0 0;
    }
  }

  a {
    text-decoration: underline;
  }
}

.checkboxLabel__text {
  @include font-body;
}
