@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.StandaloneMedia {
  padding: 0 var(--page-gutter);
}

.titleContainer {
  padding-bottom: px(16);
  margin-bottom: px(16);
  border-bottom: 1px solid var(--text-color-subtle);

  @include bp(mobile) {
    padding-bottom: px(24);
    margin-bottom: px(40);
  }
}

.titleContainer__title {
  @include font-subheader;
  color: var(--text-color-subtle);
}

.imageContainer {
  aspect-ratio: 1/1;
  position: relative;

  @include bp(mobile) {
    aspect-ratio: 1294/728;
  }
}

.image {
  @include position-100(absolute);
  object-fit: cover;
}
