@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.BlogContent {
  padding: 0 var(--page-gutter);
}

.headerContent {
  display: flex;
  flex-direction: column;
}

.category {
  @include font-subheader;
  color: var(--text-color-subtle);
  margin-bottom: px(24);
}

.heading {
  @include font-h1;
  margin-bottom: px(24);
}

.subtitle {
  @include font-body-large;
  color: var(--text-color-subtle);
  max-width: px(765);
  margin-bottom: px(18);

  @include bp(tablet) {
    margin-bottom: px(56);
  }
}

.imageContainer {
  display: block;
  width: 100%;
  position: relative;
  aspect-ratio: 1/1;

  @include bp(tablet) {
    aspect-ratio: 1296/627;
  }
}

.image {
  @include position-100(absolute);
  object-fit: cover;
}

.richTextContent {
  position: relative;
  margin-top: px(44);

  @include bp(tablet) {
    margin-top: px(40);
  }
}

.authorContent {
  @include font-small-subheader;
  padding-right: px(40);
  padding-top: px(15);
  border-top: 1px solid var(--dark-purple-light-2);
  color: var(--text-color-subtle);
  display: inline-block;
  margin-bottom: px(40);

  @include bp(tablet) {
    margin-bottom: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
}
