@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.TitleAndRichTextScroller {
}

.inner {
  padding: 0 var(--page-gutter);
}

.topContent {
  @include grid;
}

.titleColumn {
  grid-column: 1/-1;
  margin-bottom: px(16);

  @include bp(mobile) {
    grid-column: 1/7;
  }
}

.titleContainer {
  @include bp(mobile) {
    position: sticky;
    top: calc(var(--header-height) + #{px(20)});
  }
}

.eyebrow {
  @include font-subheader;
  margin-bottom: px(8);
}

.title {
  @include font-h2;
  white-space: pre-line;
}

.contentColumn {
  grid-column: 1/-1;

  @include bp(mobile) {
    grid-column: 7/-1;
  }
}

.content {
  p,
  .richTextImageContainer {
    margin: px(22) 0;

    @include bp(mobile) {
      margin: px(40) 0;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.bottomContent {
  margin-top: px(20);

  @include bp(mobile) {
    margin-top: px(40);
  }
}

.richTextImage,
.bottomImage {
  display: block;
  aspect-ratio: var(--aspect-ratio);
  height: unset;
  width: 100%;
}
