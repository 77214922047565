@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.YellowQuote {
  position: relative;
  height: 200lvh;
  z-index: 2;
}

.bg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% + 50lvh);
  background-color: var(--yellow);
  opacity: 0;
  pointer-events: none;
}

.inner {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100lvh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoContainer {
  @include position-100(absolute);
  @include flex-center;
  overflow: hidden;
  opacity: 0;

  svg {
    width: 100%;
    transform: scale(2) !important;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 2;
  padding: var(--gutter);
}

.eyebrow {
  @include font-subheader;
  color: var(--text-color-subtle);
  margin-bottom: px(24);
}

.titleContainer {
  width: 100%;
}

.title {
  @include font-h2;
  margin-bottom: px(17);
  max-width: px(840);
  width: 100%;

  @include bp(mobile) {
    white-space: pre-line;
  }
}

.description {
  @include font-body-large;
  color: var(--text-color-subtle);
  max-width: 75%;
  text-align: center;
  margin: 0 auto;

  @include bp(mobile) {
    max-width: px(700);
  }
}
