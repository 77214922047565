@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.SideBySides {
  padding: 0 var(--page-gutter);
}

.title {
  @include font-h3;
  margin-bottom: px(54);
}

.items {
  display: flex;
  flex-direction: column;
}

.stickySpacer {
  top: var(--header-height);
  position: sticky;
  height: 50svh;
  pointer-events: none;
}
