@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.SideBySidesItem {
  padding-top: px(25);
  border-top: 1px solid var(--dark-purple-light-2);
  background-color: var(--bg-color);
  padding-bottom: px(25);

  &[data-item-length='1'] {
    border-top: none;
  }

  &.isLast:not(:first-child) {
    border-bottom: 1px solid var(--dark-purple-light-2);
  }

  &.isStacked {
    position: sticky;
    top: var(--header-height);
    left: 0;
    width: 100%;
  }
}

.inner {
  @include grid;
  row-gap: px(24);
}

.textContentGrid {
  order: 2;
  grid-column: 1 / -1;

  @include bp(mobile) {
    order: 1;
    grid-column: 1 / 8;
  }

  @include bp(laptop) {
    grid-column: 1 / 7;
  }

  .hasLottieAnimation & {
    display: grid;
    grid-template-columns: var(--mobile-lottie-size) 1fr;
    gap: var(--mobile-gap-size);

    --mobile-lottie-size: #{px(40)};
    --mobile-gap-size: #{px(16)};

    @include bp(mobile) {
      gap: px(24);
      grid-template-columns: #{px(50)} 1fr;
    }

    @include bp(laptop) {
      gap: px(41);
      grid-template-columns: #{px(67)} 1fr;
    }
  }
}

.lottieContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  svg {
    height: auto !important;
  }
}

.eyebrow {
  @include font-subheader;
  color: var(--text-color-subtle);
  margin-bottom: px(8);
}

.title {
  @include font-h2;
  white-space: pre-line;
  margin-bottom: px(24);
}

.descriptionContainer {
  margin-left: calc(calc(var(--mobile-lottie-size) + var(--mobile-gap-size)) * -1);
  width: calc(100% + calc(var(--mobile-lottie-size) + var(--mobile-gap-size)));

  @include bp(mobile) {
    max-width: px(489);
    margin-left: 0;
    width: 100%;
  }
}

.button {
  margin-top: px(30);
  margin-left: calc(calc(var(--mobile-lottie-size) + var(--mobile-gap-size)) * -1);

  @include bp(mobile) {
    margin-left: 0;
  }
}

.imageContent {
  grid-column: 1/-1;
  order: 1;

  @include bp(mobile) {
    order: 2;
    grid-column: 9/-1;
  }

  @include bp(laptop) {
    grid-column: 8/-1;
  }
}

.imageContainer {
  aspect-ratio: 1/1;
  position: relative;

  [data-image-orientation='tall'] & {
    aspect-ratio: 531/709;

    @include bp(mobile, true) {
      aspect-ratio: 1/1;
    }
  }
}

.image {
  @include position-100(absolute);
  object-fit: cover;
}

.imageCaption {
  @include font-small-subheader;
  margin-top: px(18);
  color: var(--text-color-subtle);
  max-width: px(420);
}
