@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.ThreeUpBlogPosts {
}

.inner {
  display: block;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--page-gutter);
  margin-bottom: px(24);
  position: relative;

  @include bp(mobile) {
    margin-bottom: px(64);
    align-items: center;
    justify-content: flex-start;
  }
}

.keylineContainer {
  display: block;
  width: 100%;
  padding: 0 var(--page-gutter);
  padding-bottom: px(48);

  @include bp(tablet) {
    padding-bottom: px(64);
  }
}

.keyline {
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--dark-purple-light-2);
}

.arrowContainer {
  display: flex;
  gap: px(10);

  @include bp(mobile) {
    display: none;
  }
}

.arrowLeft,
.arrowRight {
  @include reset-button;
  @include flex-center;
  height: px(30);
  width: px(30);

  svg {
    width: 100%;
    display: block;
  }
}

.arrowLeft {
  transform: rotate(-180deg);
}

.title {
  @include font-h3;
}

.listOuter {
  @include gpu;
  @include hide-scrollbar;
  overflow: auto;
  max-width: 100%;

  @include bp(mobile) {
    padding: 0 var(--page-gutter);
  }
}

.list {
  display: grid;
  grid-template-columns: repeat(3, 80vw);
  width: min-content;
  padding: 0 calc(var(--page-gutter) - calc(var(--gutter) * 0.5));

  @include bp(mobile) {
    column-gap: var(--gutter);
    padding: 0;
    width: auto;
    grid-template-columns: repeat(3, 1fr);
  }
}

.card {
  width: 100%;
  padding: 0 calc(var(--gutter) * 0.5);

  @include bp(mobile) {
    padding: 0;
  }
}
