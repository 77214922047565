@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.MaskReveal {
  position: relative;
  overflow: hidden;
  background-color: var(--bg-subtle);
}

.scaleContainer {
  transform: scale(1.3);
  opacity: 0;
  height: 100%;
  width: 100%;
  position: relative;
}
