@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.Footer {
  padding: 0 var(--page-gutter);
  background-color: var(--dark-purple);

  --title-margin: #{px(24)};

  @include bp(tablet) {
    --title-margin: #{px(27)};
  }
}

.top {
  @include grid;
  row-gap: px(47);
  margin-bottom: #{px(90)};

  @include bp(tablet) {
    margin-bottom: #{px(140)};
  }

  @include bp(laptop) {
    margin-bottom: #{px(240)};
  }
}

.linkColumn {
  @include bp(mobile, true) {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    gap: var(--gutter);
  }

  &:nth-child(1) {
    grid-column: 1/-1;

    @include bp(mobile) {
      grid-column: 1/3;
    }
  }

  &:nth-child(2) {
    grid-column: 1/-1;

    @include bp(mobile) {
      grid-column: 3/5;
    }
  }
}

.linkColumn__listItemLink {
  display: block;
  text-decoration: underline;
}

.linkColumn__title,
.linkColumn__listItemLink,
.addressColumn__address,
.addressColumn__title,
.legalColumn__title,
.legalColumn__legal {
  @include font-small-subheader;
}

.linkColumn__title {
  margin-bottom: var(--title-margin);
}

.linkColumn__list {
  display: flex;
  flex-direction: column;
  gap: px(8);
  margin-top: px(8);

  @include bp(mobile) {
    margin-top: 0;
    gap: px(10);
  }
}

.addressColumn {
  grid-column: 1/-1;

  @include bp(mobile, true) {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    gap: var(--gutter);
  }

  @include bp(mobile) {
    grid-column: 5/9;
  }
}

.addressColumn__title {
  margin-bottom: var(--title-margin);
}

.addressColumn__address {
  white-space: pre-line;

  @include bp(mobile, true) {
    margin-top: px(8);
  }
}

.legalColumn {
  grid-column: 1/-1;

  @include bp(mobile) {
    grid-column: 9/-1;
    text-align: right;
  }
}

.legalColumn__title {
  margin-bottom: var(--title-margin);
}

.legalColumn__legal {
  white-space: pre-line;
}

.bottom {
  display: grid;
  gap: var(--gutter);
  grid-template-columns: 1fr #{px(111)};
  align-items: end;

  @include bp(mobile) {
    align-items: center;
    grid-template-columns: 1fr #{px(200)};
  }
}

.bottomTitle {
  @include font-h2;

  @include bp(mobile) {
    white-space: pre-line;
  }
}

.lottieContainer {
  display: block;

  path {
    fill: var(--yellow);
  }
}
