@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.PaginatedBlogPostsCard {
  width: 100%;

  @include bp(mobile) {
    width: calc(50% - calc(var(--gutter) * 0.5));
  }

  @include bp(tablet) {
    width: calc(33.3333% - calc(var(--gutter) * 0.7));
  }

  &.isBig {
    @include bp(tablet) {
      width: 100%;
    }
  }
}

.link {
  display: flex;
  flex-direction: column;
  width: 100%;

  .isBig & {
    @include bp(tablet) {
      display: grid;
      grid-template-columns: 1fr 0.8fr;
      flex-direction: row;
    }
  }
}

.content {
  .isBig & {
    @include bp(tablet) {
      order: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}

.title {
  @include font-h4;
  margin-top: px(24);

  .isBig & {
    @include bp(tablet) {
      @include font-h2;
      margin-top: 0;
    }
  }
}

.filterDateContainer {
  @include font-small-subheader;
  color: var(--text-color-subtle);
  margin-top: px(16);

  .isBig & {
    @include bp(tablet) {
      margin-top: auto;
    }
  }
}

.subtitle {
  @include font-body;
  color: var(--text-color-subtle);
  margin-top: px(16);
  margin-bottom: 0;

  .isBig & {
    @include bp(tablet) {
      max-width: px(445);
    }
  }
}

.buttonContainer {
  display: inline-flex;
}

.button {
  margin-top: px(16);
  display: inline-flex;
}

.imageContainer {
  aspect-ratio: 1/1;
  position: relative;

  .isBig & {
    @include bp(tablet) {
      order: 2;
    }
  }
}

.imageContainer__image {
  @include position-100(absolute);
  object-fit: cover;
}
