@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.FourOhFour {
  @include flex-center;
  padding: 0 var(--page-gutter);
  height: 100svh;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.title {
  @include font-h1;
}

.link {
  @include font-small-subheader;
  text-decoration: underline;
}
