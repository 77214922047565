@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.RichTextBlock {
  @include grid;
}

.inner {
  padding: 0 var(--page-gutter);
  grid-column: 1/-1;

  @include bp(laptop) {
    grid-column: 1/9;
  }
}

.headerContent {
  margin-bottom: px(80);

  @include bp(tablet) {
    margin-bottom: px(106);
  }
}

.eyebrow {
  @include font-subheader;
  color: var(--text-color-subtle);
  margin-bottom: px(8);
}

.title {
  @include font-h2;
}

.subtitle {
  @include font-body;
  color: var(--text-color-subtle);
  margin-top: px(24);
}

.content {
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .h2 {
    @include font-h2-mobile;
    margin-bottom: px(32);
    margin-top: px(40);

    @include bp(tablet) {
      @include font-h3;
      margin-bottom: px(42);
      margin-top: px(80);
    }
  }

  .h3 {
    @include font-h4;
    margin: px(32) 0 px(13);

    @include bp(tablet) {
      margin: px(18) 0;
    }
  }
}
