@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.Content {
}

.contentInnerWrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: px(16);
  justify-content: flex-start;
  max-width: px(900);
  padding: 0 0 px(24);

  @include bp(mobile) {
    padding: 0 px(24) px(24);
  }
}

.descriptionString {
  white-space: pre-line;
}
