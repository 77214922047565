@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.Button {
  @include reset-button;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  color: var(--button-color);
  position: relative;
  overflow: hidden;
  height: px(35);
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  transition: opacity var(--animation-duration);

  --animation-duration: 0.3s;

  &[data-type='primary'] {
    min-width: px(195);
    gap: px(35);
    border-bottom: 1px solid var(--button-border);

    &.hover {
      color: var(--button-color-hover);
    }
  }

  &[data-type='secondary'] {
    gap: px(10);
  }

  &[data-type='secondary'],
  &[data-type='small'] {
    &.hover {
      color: var(--button-color-hover-secondary);
    }
  }

  &:disabled {
    opacity: 0.7;
    pointer-events: none;
  }
}

.label {
  white-space: nowrap;
  position: relative;
  z-index: 2;
  transition: color var(--animation-duration), transform var(--animation-duration);

  [data-type='secondary'] & {
    @include font-small-subheader;
  }

  [data-type='primary'] &,
  [data-type='small'] & {
    @include font-button;
  }

  [data-type='primary'].hover & {
    transform: translateX(#{px(12)});
  }
}

.longArrow,
.rotatedArrow {
  transition: color var(--animation-duration), transform var(--animation-duration);
  position: relative;
  z-index: 2;

  [data-type='primary'].hover & {
    transform: translateX(#{px(-12)});
  }
}

.longArrow {
  width: px(34);
}

.rotatedArrow {
  width: px(10);
}

.bg {
  @include position-100(absolute);
  z-index: 1;
  transform: scaleY(0);
  transform-origin: bottom;
  background-color: var(--button-bg);
  transition: transform var(--animation-duration);

  .hover & {
    transform: scale(1);
  }
}
