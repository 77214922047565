@import "@/styles/shared";
.Accordion {
  margin-bottom: px(48);
}

.item {
  overflow: hidden;
  transition: background-color 0.3s;
  border-top: 1px solid var(--text-color);

  &:last-child {
    border-bottom: 1px solid var(--text-color);
  }

  /* stylelint-disable-next-line selector-not-notation */
  &.isHovering:not(.isOpen):not(.noDescription) {
    @include hover {
      background-color: var(--purple);
    }
  }
}

.contentWrapper {
  height: 0;
  overflow: hidden;
}

.contentWrapperOpen {
  height: auto;
}
