@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.Accordion {
  padding: 0 var(--page-gutter);
}

.title {
  @include font-subheader;
  color: var(--text-color-subtle);
  margin-bottom: px(30);
}
