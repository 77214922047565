@import "@/styles/shared";
.bold {
  font-weight: 500;
  color: var(--text-color);
}

.title {
  @include font-h2;
  color: var(--text-color-subtle);
}
