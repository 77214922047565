@import "@/styles/shared";
.container {
  @include grid;

  --content-columns: 1/-1;
  --element-margin: #{px(13)};
  --element-margin-half: #{px(13) / 2};

  @include bp(tablet) {
    --element-margin: #{px(17)};
    --element-margin-half: #{px(17) / 2};
    --content-columns: 5/-1;
  }

  @include bp(desktop) {
    --content-columns: 6/12;
  }

  & > :first-child {
    margin-top: 0;
  }
}

.richTextLink {
  text-decoration: underline;
}

.p,
.ul {
  margin: var(--element-margin-half) 0;
}

.p,
.li {
  @include font-body;
  color: var(--text-color-subtle);
}

.p,
.ul,
.h2,
.h3,
.linkWrapper,
.pdfContactFormContainer,
.footnote {
  grid-column: var(--content-columns);
}

.h2 {
  @include font-h3;
  margin: calc(#{px(24)} - var(--element-margin-half)) 0;

  @include bp(tablet) {
    margin: calc(#{px(40)} - var(--element-margin-half)) 0;
  }
}

.h3 {
  @include font-h4;
  margin: calc(#{px(30)} - var(--element-margin)) 0 var(--element-margin);

  @include bp(tablet) {
    margin: calc(#{px(40)} - var(--element-margin)) 0 var(--element-margin);
  }
}

.ul {
  list-style: disc;
  padding-left: px(15);
}

.blockquote {
  @include font-h2;
  grid-column: 1/-1;
  margin: calc(#{px(24)} - var(--element-margin)) 0;

  @include bp(tablet) {
    margin: calc(#{px(60)} - var(--element-margin)) 0;
  }
}

.linkWrapper,
.imageBlockFadeIn,
.pdfContactFormContainer {
  margin: calc(#{px(24)} - var(--element-margin)) 0;

  @include bp(tablet) {
    margin: calc(#{px(45)} - var(--element-margin)) 0;
  }
}

.imageBlockFadeIn {
  grid-column: 1/-1;

  &.inline {
    grid-column: var(--content-columns);
  }
}

.imageBlock {
  aspect-ratio: var(--aspect-ratio);
  position: relative;
}

.imageBlock__image {
  @include position-100(absolute);
  object-fit: cover;
}

.imageBlock__text {
  white-space: pre-line;
  @include font-small-subheader;
  color: var(--text-color-subtle);
  margin-bottom: px(15);

  &.full {
    grid-column: 1/-1;

    @include bp(tablet) {
      grid-column: 1/4;
    }
  }

  &.inline {
    grid-column: var(--content-columns);
    margin-bottom: px(15);

    @include bp(tablet) {
      margin-bottom: px(35);
    }
  }

  &.serifCaption {
    @include font-image-caption;
    color: var(--text-color);
    margin-top: calc(var(--element-margin) * -0.2);

    @include bp(tablet) {
      margin-top: calc(var(--element-margin) * -0.9);
    }
  }
}

.vimeoEmbed {
  max-width: 100%;
  grid-column: var(--content-columns);
  margin: calc(#{px(24)} - var(--element-margin)) 0;

  &.isFullWidth {
    grid-column: 1/-1;

    @include bp(tablet) {
      grid-column: 2/-1;
      margin: calc(#{px(60)} - var(--element-margin)) 0;
    }
  }
}

.footnote {
  margin: calc(#{px(18)} - var(--element-margin)) 0;
  padding-top: px(15);
  border-top: 1px solid var(--dark-purple-light-2);

  p {
    @include font-image-caption;
    color: var(--text-color);
    margin: px(7) 0;
  }
}
